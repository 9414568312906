<template>
    <Header />
    <div class="container container-width">
        <h2>Approval Pending</h2>
        <p class="lead text-start">
            Your account has not been assigned a role and is currently under review.
        </p>
        <p class="lead text-start">
            You will not be able to use this application until your account has been assigned a role.
        </p>
        <p class="text-muted">
            Please contact your Project Manager if you have any questions.
        </p>
    </div>
</template>

<script>
import store from '../../shared/store';
import Header from '../../shared/navigation/Header.vue';
import { USER_ROLE } from '../../shared/constants';
export default {
    name: 'PendingApproval',
    components: {
        Header
    },
    mounted() {
        if (store.getters.userDetails?.role !== USER_ROLE.USER) {
            this.$router.replace({ path: store.getters.landingPage });
        }
    }
};
</script>

<style scope>
.container{
    margin-top:6rem;
}
</style>
